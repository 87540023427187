<script>
import { computed } from 'vue';
import Button from '@/components/Button.vue';
import ResolveObject from '@/utilities/ResolveObject';

export default {
  props: {
    headers: Array,
    data: Object,
  },
  components: {
    Button,
  },
  setup(props, context) {
    const gridColumns = computed(() => {
      const widths = [];

      props.headers.forEach((header) => {
        if (header.width) {
          widths.push(header.width);
        } else {
          widths.push('1fr');
        }
      });

      return widths.join(' ');
    });

    function clickButton(emitEvent) {
      if (emitEvent.length > 0) {
        context.emit('buttonEvent', {
          event: emitEvent,
          data: props.data,
        });
      }
    }

    return {
      gridColumns,
      clickButton,
      ResolveObject,
    };
  },
};
</script>
<template>
  <div
    class="row"
    :style="{ gridTemplateColumns: gridColumns }"
  >
    <div
      v-for="header in headers"
      :key="header.key"
      :class="['col', header.alignment]"
    >
      <Button
        @click="clickButton(header.emitEvent)"
        size="small"
        type="secondary"
        v-if="header.type === 'button'"
      >
        {{header.buttonText}}
      </Button>
      <div class="image" v-else-if="header.type === 'image'">
        <img :src="ResolveObject(header.key, data)" />
      </div>
      <template v-else>
        {{ResolveObject(header.key, data)}}
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.image {
  width: $INPUT_HEIGHT * 1.5 - 0.5rem;
  height: $INPUT_HEIGHT * 1.5 - 0.5rem;
  overflow: hidden;
  border-radius: 100%;
  border: 1px solid white;

  img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}

.row {
  display: grid;
  height: $INPUT_HEIGHT * 1.5;
  align-items: center;
  background: $TABLE_EVEN_BG;
  font-size: 1rem;
  border-bottom: 1px solid $TABLE_BORDER_COLOR;

  &:nth-child(2n) {
    background: $TABLE_ODD_BG;
  }

  .col {
    padding: 0 1rem;

    &.center {
      text-align: center;
    }
    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
  }
}
</style>
