<script>
import { computed } from 'vue';

export default {
  props: {
    headers: Array,
  },
  setup(props) {
    const gridColumns = computed(() => {
      const widths = [];

      props.headers.forEach((header) => {
        if (header.width) {
          widths.push(header.width);
        } else {
          widths.push('1fr');
        }
      });

      return widths.join(' ');
    });

    return {
      gridColumns,
    };
  },
};
</script>
<template>
  <div
    class="header"
    :style="{ gridTemplateColumns: gridColumns }"
  >
    <div
      v-for="header in headers"
      :key="header.key"
      :class="['col', header.alignment]"
    >
      {{header.label}}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.header {
  display: grid;
  height: $INPUT_HEIGHT;
  align-items: center;
  background: $TABLE_HEADER_BG;
  //text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
  border-top: 1px solid $TABLE_BORDER_COLOR;
  border-bottom: 1px solid $TABLE_BORDER_COLOR;

  .col {
    padding: 0 1rem;
    &.center {
      text-align: center;
    }
    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
  }
}
</style>
