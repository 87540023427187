<script>
import TableHeader from './TableHeader.vue';
import TableRow from './TableRow.vue';

export default {
  props: {
    data: Array,
    headers: Array,
  },
  components: {
    TableHeader,
    TableRow,
  },
  setup(props, context) {
    function clickButton(event) {
      context.emit(event.event, event.data);
    }
    return {
      clickButton,
    };
  },
};
</script>
<template>
  <div class="table">
    <TableHeader :headers="headers" />
    <TableRow
      v-for="row in data"
      :key="row.name"
      :headers="headers"
      :data="row"
      @buttonEvent="clickButton"
    />
  </div>
</template>
<style lang="scss" scoped>

</style>
